export const environment = {
  production: true,
  qa: false,
  firebase: {
    apiKey: 'AIzaSyDc-GTDymRBhw1YNSEuGNepIQVedn9gaB8',
    authDomain: 'russells-web.firebaseapp.com',
    databaseURL: 'https://russells-web.firebaseio.com',
    projectId: 'russells-web',
    storageBucket: 'russells-web.appspot.com',
    messagingSenderId: '700413573653',
    appId: '1:700413573653:web:1d43a7ce137d69372f6c1c',
    measurementId: 'G-5VHZNLG7RF'
  },
  stripe: {
    pk: 'pk_live_51Je3EEJQSMCjsyVmPjEERVKaqNjjB5TEv40Ab6uaWTdcHElBhsbDPaJGWcApg7pZRKgdZ79ZUBIkUVtIAXAEnnqf003Jr9KNvE',
    gcPaymentLinks: [
      {
        amount: '$25',
        paymentLink: 'https://buy.stripe.com/eVa02Y8a965q42A288?client_reference_id=giftcard'
      },
      {
        amount: '$50',
        paymentLink: 'https://buy.stripe.com/4gw3fa621gK4fLibIJ?client_reference_id=giftcard'
      },
      {
        amount: '$100',
        paymentLink: 'https://buy.stripe.com/8wMdTObmlctO0Qo146?client_reference_id=giftcard'
      },
      {
        amount: '$150',
        paymentLink: 'https://buy.stripe.com/fZedTOdut3Xibv2148?client_reference_id=giftcard'
      },
      {
        amount: '$200',
        paymentLink: 'https://buy.stripe.com/dR65ni3TT1PagPmeUZ?client_reference_id=giftcard'
      },
      {
        amount: '$250',
        paymentLink: 'https://buy.stripe.com/14kg1W2PP79uaqYbIO?client_reference_id=giftcard'
      }
    ],
    designDepositPaymentLink: 'https://book.stripe.com/7sIbLGaihctOfLieV1?client_reference_id='
  }
};
